<template>
<div>
    <el-button @click="loadBitSetAsBinary">load bitset as binary</el-button>
    <el-button @click="loadBitSetAsJson">load bitset as json</el-button>
    <el-button @click="bitSetSubmit">submit bitset as json</el-button>
</div>
</template>

<script>
import axios from 'axios';
import BitSet from 'mnemonist/bit-set';

function toBitset(array) {
    if(!(array instanceof Uint32Array)) {
        if(!array) {
            throw new Error('Array is required')
        }
        if(array instanceof ArrayBuffer) {
            return toBitset(new Uint32Array(array));
        }
        if(array.buffer instanceof ArrayBuffer) {
            return toBitset(new Uint32Array(array.buffer));
        }
        throw new Error('Unsupported array type')
    }
    const bitSet = new BitSet(array.length * 32);
    bitSet.array = array;
    bitSet.forEach((bit, i) => {bit && bitSet.size++});
    return bitSet;
}

function bitSetIndices(bitSet) {
    if(!bitSet || !bitSet.forEach) return [];
    const indices = [];
    bitSet.forEach((bit, i) => {
        if(bit) indices.push(i);
    });
    return indices;
}

const Base64 = (() => {
    const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/';

    // Use a lookup table to find the index.
    const lookup = typeof Uint8Array === 'undefined' ? [] : new Uint8Array(256);
    for (let i = 0; i < chars.length; i++) {
        lookup[chars.charCodeAt(i)] = i;
    }

    function encode (arraybuffer) {
        const bytes = new Uint8Array(arraybuffer),
            len = bytes.length,
            base64 = [];

        let i, byte0, byte1, byte2;
        for (i = 0; i < len; i += 3) {
            byte0 = bytes[i];
            byte1 = bytes[i + 1];
            byte2 = bytes[i + 2];
            base64.push(chars[byte0 >> 2]);
            base64.push(chars[((byte0 & 3) << 4) | (byte1 >> 4)]);
            base64.push(chars[((byte1 & 15) << 2) | (byte2 >> 6)]);
            base64.push(chars[byte2 & 63]);
        }

        if (len % 3 === 2) {
            base64[base64.length - 1] = '=';
        } else if (len % 3 === 1) {
            base64[base64.length - 1] = '=';
            base64[base64.length - 2] = '=';
        }
        return base64.join('');
    }

    function decode(base64){
        let bufferLength = base64.length * 0.75,
            len = base64.length,
            i,
            p = 0,
            encoded1,
            encoded2,
            encoded3,
            encoded4;

        if (base64[base64.length - 1] === '=') {
            bufferLength--;
            if (base64[base64.length - 2] === '=') {
                bufferLength--;
            }
        }

        const arraybuffer = new ArrayBuffer(bufferLength),
            bytes = new Uint8Array(arraybuffer);

        for (i = 0; i < len; i += 4) {
            encoded1 = lookup[base64.charCodeAt(i)];
            encoded2 = lookup[base64.charCodeAt(i + 1)];
            encoded3 = lookup[base64.charCodeAt(i + 2)];
            encoded4 = lookup[base64.charCodeAt(i + 3)];

            bytes[p++] = (encoded1 << 2) | (encoded2 >> 4);
            bytes[p++] = ((encoded2 & 15) << 4) | (encoded3 >> 2);
            bytes[p++] = ((encoded3 & 3) << 6) | (encoded4 & 63);
        }

        return arraybuffer;
    }
    return {encode, decode};
})();

function toBase64(bitSet) {
    return !bitSet ? null : Base64.encode(bitSet.array.buffer);
}
function fromBase64(base64) {
    return !(base64 && base64.length) ? null : toBitset(new Uint32Array(Base64.decode(base64)));
}

export default {
    name: 'Tests',
    methods: {
        loadBitSetAsBinary() {
            axios.post(`${this.$store.state.apiUrl}/test/bitSetAsBinary`, [0, 2,3, 5,6,7], {
                responseType: 'arraybuffer',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/octet-stream'
                }
            }).then(resp => {
                // Документация к BitSet-у:
                // https://yomguithereal.github.io/mnemonist/bit-set.html
                const bitSet = toBitset(new Uint32Array(resp.data));

                const result = [], indices = [];
                bitSet.forEach((bit, i) => {
                    result.push(bit);
                    if(bit) indices.push(i);
                });
                console.info(result.join(''), indices);
            });
        },
        loadBitSetAsJson() {
            const indices = [0, 2, 3, 5, 6, 7, 50];
            this.$services.test.bitSetAsJson(indices).then(resp => {
                // Документация к BitSet-у:
                // https://yomguithereal.github.io/mnemonist/bit-set.html
                const bitSet = fromBase64(resp.data.bytes);

                console.info('request indices', indices, 'bitset indices', bitSetIndices(bitSet));

                const toBase64Val = toBase64(bitSet);
                console.info('response base64', resp.data.bytes, 'bitset toBase64', toBase64Val);

                console.info('request indices', indices, 'bitset toBase64 indices', bitSetIndices(fromBase64(toBase64Val)));
            });
        },
        bitSetSubmit() {
            const indices = [0, 2, 3, 5, 6, 7, 50];
            const bitSet = new BitSet(indices.reduce((a, b) => a > b ? a : b, 0));
            indices.forEach(i => bitSet.set(i, true))
            this.$services.test.bitSetSubmit({bytes: toBase64(bitSet)}).then(resp => {
                console.info('request indices', indices, 'response indices', resp.data);
            });
        }
    }
}
</script>

<style scoped>

</style>
